import axios from "axios";

export async function createCustomer(data) {
    try {
        const response = await axios({
            method: "post",
            url: "https://edv.the-oaks.my/add_customer.php",
            data: JSON.stringify(data),
        });
        return response.data;
    } catch (error) {
        return null;
    }
}

export async function createUser(data) {
    const response = await axios.post(
        `https://whattowear-mel.myshopify.com/admin/api/2022-04/customers.json`,
        { customer: data }
    );
    return response.data;
}
