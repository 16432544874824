import React from "react";
import logo from "../images/logo.svg";
import StoreLocation from "../images/store-locations.png";
import FacebookIcon from "../images/facebook-icon.svg";
import InstagramIcon from "../images/instagram-icon.svg";
import { NavLink } from "react-router-dom";
import BackIcon from "../images/back-icon.svg";

class StoreLocator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="store-location">
                <nav className="navbar navbar-light justify-content-start">
                    <NavLink to="/" className="btn">
                        <img src={BackIcon} alt="back icon" height="25" />
                    </NavLink>
                </nav>
                <div className="container d-flex flex-column align-items-center">
                    <img src={logo} alt="logo" width="200px" />
                    <h3
                        className="mt-5"
                        style={{
                            "font-size": "1.35rem",
                            "margin-bottom": "12px",
                        }}
                    >
                        Store Location
                    </h3>
                    <div className="card">
                        <div className="input">
                            <div className="shop">ISETAN, Lot 10</div>
                            <div className="address">
                                Isetan @ Lot 10, L1-H, Lot 10, 50, Jalan Sultan
                                Ismail, 55100 Kuala Lumpur.
                            </div>
                        </div>
                        <div className="input">
                            <div className="shop">ISETAN, The Gardens Mall</div>
                            <div className="address">
                                Isetan @ The Gardens Mall, Lot 233, The Gardens
                                Mall, Mid Valley City, 58000 Kuala Lumpur.
                            </div>
                        </div>
                        <div className="input">
                            <div className="shop">Ipoh Old Town</div>
                            <div className="address">
                                60, Jalan Bandar Timah, 30000 Ipoh, Perak,
                                Malaysia.
                            </div>
                        </div>
                        <div className="input">
                            <div className="shop">Ipoh Old Town</div>
                            <div className="address">
                                31, Jalan Bandar Timah, 30000 Ipoh, Perak,
                                Malaysia.
                            </div>
                        </div>
                        <div className="input">
                            <div className="shop">Ipoh Old Town</div>
                            <div className="address">
                                64, Jalan Bandar Timah, 30000 Ipoh, Perak,
                                Malaysia.
                            </div>
                        </div>
                    </div>
                    {/* <img src={StoreLocation} alt="logo" width="100%" /> */}
                    <h3
                        className="mt-4"
                        style={{
                            "font-size": "1.25rem",
                            "margin-bottom": "14px",
                        }}
                    >
                        Follow Us On:
                    </h3>
                    <div className="social-icons d-flex justify-content-center">
                        <a href="https://www.facebook.com/whattowearmalaysia/">
                            <img
                                src={FacebookIcon}
                                alt="logo"
                                width="25"
                                height="25"
                            />
                        </a>
                        <a href="https://www.instagram.com/whattowear.ai/">
                            <img
                                src={InstagramIcon}
                                alt="logo"
                                width="25"
                                height="25"
                            />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default StoreLocator;
