import React from "react";
import logo from "../images/logo.svg";
import successMessage from "../images/success-message.svg";
import failMessage from "../images/fail-message.svg";
import Modal from "react-modal";
import { createCustomer } from "../utils/Requests";
import { NavLink } from "react-router-dom";
import DropdownIcon from "../images/burger-icon.svg";

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: true,
            lastName: "",
            email: "",
            phone: "",
            hasError: false,
            fieldError: [false, false, false],
            successModalIsOpen: false,
            failModalIsOpen: false,
        };
    }

    handleOnChange() {
        this.setState({ isChecked: !this.state.isChecked });
    }

    promisedSetState = (newState) =>
        new Promise((resolve) => this.setState(newState, resolve));

    async handleSubmit(event) {
        event.preventDefault();

        await this.promisedSetState({
            fieldError: [false, false, false],
            hasError: false,
            errorMessage:
                "Please make sure you’ve keyed in proper info before proceed.",
        });

        // Check if last name empty
        if (this.state.lastName === "") {
            this.state.fieldError[0] = true;
            await this.promisedSetState({ fieldError: this.state.fieldError });
        }

        // Check if email empty or invalid email format
        if (
            this.state.email === "" ||
            !String(this.state.email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            this.state.fieldError[1] = true;
            await this.promisedSetState({ fieldError: this.state.fieldError });
        }

        // Check if phone empty or invalid format
        if (
            this.state.phone === "" ||
            this.state.phone.match(/^[0-9]+$/) == null
        ) {
            this.state.fieldError[2] = true;
            await this.promisedSetState({ fieldError: this.state.fieldError });
        }

        // If have any error then show error
        var _hasError = false;
        this.state.fieldError.forEach(async (fieldHasError) => {
            if (fieldHasError) {
                _hasError = true;
                await this.promisedSetState({ hasError: true });
            }
        });

        if (!_hasError) {
            this.signUp();
        }
    }

    async signUp() {
        // Call API here
        const result = await createCustomer({
            email: this.state.email,
            phone: this.state.phone,
            last_name: this.state.lastName,
            accepts_marketing: this.state.isChecked,
        });

        if (
            result.errors &&
            result.errors.phone &&
            result.errors.phone[0] ===
                "Enter a valid phone number to use this delivery method"
        ) {
            this.setState({ fieldError: [false, false, true], hasError: true });
            return;
        }

        if (result.errors != null) {
            this.setState({ failModalIsOpen: true });
            return;
        }

        this.setState({ successModalIsOpen: true });
    }

    closeSuccessModal() {
        this.setState({ successModalIsOpen: false });
    }

    closeFailModal() {
        this.setState({ failModalIsOpen: false });
    }

    render() {
        return (
            <div className="home-page">
                <nav className="navbar navbar-light justify-content-end">
                    <NavLink to="/store-location" className="btn">
                        <img
                            src={DropdownIcon}
                            alt="dropdown icon"
                            width="35"
                        />
                    </NavLink>
                </nav>
                <div className="container d-flex flex-column align-items-center">
                    <img src={logo} alt="logo" width="200px" />
                    <p className="description" style={{ marginTop: "42px" }}>
                        We are launching a series of clothes and accessories
                        that are specially curated for you.
                    </p>
                    <p className="description" style={{ marginTop: "2px" }}>
                        Claim exclusive vouchers and perks below by signing up
                        for our future newsletter!
                    </p>

                    <div className="form" style={{ marginTop: "38px" }}>
                        <input
                            type="text"
                            value={this.state.lastName}
                            onChange={(event) =>
                                this.setState({ lastName: event.target.value })
                            }
                            className={
                                "form-control last-name " +
                                (this.state.fieldError[0] ? "hasError" : "")
                            }
                            placeholder="Name As Per IC"
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.handleSubmit(event);
                                }
                            }}
                        />
                        <input
                            type="text"
                            value={this.state.email}
                            onChange={(event) =>
                                this.setState({ email: event.target.value })
                            }
                            className={
                                "form-control email " +
                                (this.state.fieldError[1] ? "hasError" : "")
                            }
                            placeholder="Email Address"
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.handleSubmit(event);
                                }
                            }}
                        />
                        <input
                            type="text"
                            value={this.state.phone}
                            onChange={(event) =>
                                this.setState({ phone: event.target.value })
                            }
                            className={
                                "form-control phone-number " +
                                (this.state.fieldError[2] ? "hasError" : "")
                            }
                            placeholder="Phone Number"
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.handleSubmit(event);
                                }
                            }}
                        />
                        {this.state.hasError ? (
                            <div className="text-danger">
                                Please make sure you’ve keyed in proper info
                                before proceed.
                            </div>
                        ) : null}
                    </div>
                    <div className="newsletter-check d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="accept-newsletter"
                            id="accept-newsletter-checkbox"
                            checked={this.state.isChecked}
                            onChange={(event) => this.handleOnChange(event)}
                        />
                        <p className="p-0 m-0 ms-2">
                            Accept future newsletters from WHAT TO WEAR
                        </p>
                    </div>
                    <button
                        className="sign-up btn btn-light"
                        onClick={(event) => this.handleSubmit(event)}
                    >
                        Sign Up
                    </button>
                    <div className="points d-flex flex-column align-items-center">
                        <div className="label">RM600 Worth of Vouchers</div>
                        <div className="line"></div>
                        <div className="label">Membership Fees Waiver</div>
                        <div className="line"></div>
                        <div className="label">10% Off Lifetime</div>
                        <div className="line"></div>
                        <div className="label">
                            Free Personal Stylist Consultation
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="tnc">*Terms & Conditions Applied</div>
                </div>

                <Modal
                    isOpen={this.state.successModalIsOpen}
                    onRequestClose={() => {
                        this.closeSuccessModal();
                    }}
                    contentLabel=""
                >
                    <img src={successMessage} alt="logo" />
                </Modal>

                <Modal
                    isOpen={this.state.failModalIsOpen}
                    onRequestClose={() => {
                        this.closeFailModal();
                    }}
                    contentLabel=""
                >
                    <img src={failMessage} alt="logo" />
                </Modal>
            </div>
        );
    }
}
export default HomePage;
